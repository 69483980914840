import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  fontWeights,
  fontSize,
  standardColours,
  fluidFontSize,
  breakpointSizes,
} from '../styles';
import { Button } from './ui';

const StyledDonationCard = styled.article`
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? backgroundColour.hex : standardColours.white};
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const StyledImage = styled(GatsbyImage)``;

const StyledContent = styled.div`
  padding: 30px 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled.h3`
  ${fluidFontSize(
    '20px',
    '36px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  font-weight: ${fontWeights.semibold};
  line-height: 1.4;
`;

const StyledDescription = styled.p`
  ${fontSize(14)};
  line-height: 1.7;
  margin-top: 16px;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  margin-top: auto;
  align-self: start;
`;

const DonationCard = ({
  data: {
    image: { gatsbyImageData, alt },
    amount,
    description,
  },
  single,
  ...props
}) => (
  <StyledDonationCard {...props}>
    <StyledImage image={gatsbyImageData} alt={alt} />
    <StyledContent>
      <StyledHeading>{`£${amount}`}</StyledHeading>
      <StyledDescription>{description}</StyledDescription>
      <StyledButton
        to={`donate/${single ? 'pay-single' : 'pay'}`}
        removeArrow={true}
        state={{ amount: amount, regular: !single }}
      >
        Donate
      </StyledButton>
    </StyledContent>
  </StyledDonationCard>
);

export default DonationCard;

export const DonationCardFragment = graphql`
  fragment DonationCardFragment on DatoCmsFileField {
    gatsbyImageData(width: 460, height: 300)
    alt
  }
`;
